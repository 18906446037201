<template>
  <div>
    <BaseButton
      :class="$style.actionButton"
      :disabled="fetchDataForExportStatusPending"
      @click.prevent="fetchDataForExport"
      >{{
        fetchDataForExportStatusPending ? "Preparing..." : "Save As CSV"
      }}</BaseButton
    >
    <ExportDataModal :items="tracksToExport" @save="saveAsCsv" />
  </div>
</template>
<script>
import { apiStatusComputedFactory } from "@/api/helpers/apiStatusComputedFactory";
import { API_STATUS } from "@/constants/apiStatus";
import { withAsync } from "@/helpers/withAsync";
import { downloadFile } from "@/helpers";
import { pick } from "lodash-es";
import ExportDataModal from "./ExportDataModal.vue";

const { IDLE, PENDING, SUCCESS, ERROR } = API_STATUS;

const EXPORT_FIELDS = [
  "isrc",
  "iswc",
  "hfa_code",
  "title",
  "performers",
  "interested_party",
  "artist_name",
  "party_admin",
  "ipi",
  "role",
  "society",
  "confidence",
];
export default {
  name: "SaveDataActions",
  components: {
    ExportDataModal,
  },
  props: {
    tracks: {
      type: Array,
      default: () => [],
    },
    selectedTracksNum: {
      type: Number,
      default: 0,
    },
    selectedTracks: {
      type: Object,
      default: () => ({}),
    },
    fileUploadedId: {
      type: String,
    },
    fetchItemDetails: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      fetchDataForExportStatus: IDLE,
      tracksToExport: [],
    };
  },
  created() {
    this.API_STATUS = API_STATUS;
  },
  computed: {
    ...apiStatusComputedFactory("fetchDataForExportStatus"),
  },
  methods: {
    getTracksDataForSaving() {
      if (this.selectedTracksNum) {
        return Object.values(this.selectedTracks);
      }
      // If an empty array is returned, all isrc_ids will be queried on the backend
      return [];
    },
    async fetchTracksDetails(isrc_ids) {
      const start = performance.now();
      const { response, error } = await withAsync(() =>
        this.fetchItemDetails(isrc_ids)
      );

      const end = performance.now();
      console.log(`Fetch took ${(end - start) / 60} seconds.`);

      if (error) {
        console.error(error);
        return error;
      }

      return response;
    },
    saveAsCsv({ headers, fileName, data }) {
      const preparedData = data.map((obj) => {
        return Object.values(obj)
          .map((item) => `"${item}"`)
          .join(",");
      });

      downloadFile({
        data: [headers, ...preparedData].join("\n"),
        fileName,
        fileType: "text/csv",
      });
    },
    async fetchDataForExport() {
      this.fetchDataForExportStatus = PENDING;

      const isrc_ids = this.getTracksDataForSaving();
      const data = await this.fetchTracksDetails(isrc_ids);
      if (data instanceof Error) {
        this.fetchDataForExportStatus = ERROR;
      }
      this.fetchDataForExportStatus = SUCCESS;
      if (!data) return;

      const preparedData = data.reduce((acc, row) => {
        const obj = pick(row, EXPORT_FIELDS);

        if (row.name) {
          obj.interested_party = row.name;
        } else {
          obj.interested_party = obj.interested_party?.split("|")?.[0]?.trim();
        }

        if (!obj.artist_name) {
          obj.artist_name = row.alias;
        }

        if (obj.confidence) {
          obj.confidence = `${obj.confidence}%`;
        }

        acc.push(obj);

        //   Object.values(obj)
        //     .map((item) => `"${item}"`)
        //     .join(",")
        // );
        return acc;
      }, []);

      this.tracksToExport = preparedData;
      this.$vfm.show("export-data-modal");
    },
  },
};
</script>
<style lang="scss" module>
.actionButton {
  font-family: helveticaneue;
}
</style>