<template>
  <div :class="$style.tableContainer">
    <table :class="$style.table">
      <thead :class="$style.thead">
        <th :class="$style.th" v-for="header of headers" :key="header.id">
          {{ header.text }}
        </th>
      </thead>
      <tbody :class="$style.tbody">
        <tr v-for="preview of data" :key="preview.id">
          <td
            :class="$style.td"
            v-for="[key, value] of Object.entries(preview)"
            :key="`${preview.id}-${key}`"
          >
            {{ value || "null" }}
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
export default {
  props: {
    headers: {},
    data: {},
  },
};
</script>

<style lang="scss" module>
.table {
  padding-bottom: 1rem;
}
.th,
.td {
  font-size: 16px;
  padding: 0.5rem 1rem 0.5rem 1rem;
}

.thead {
  background-color: $color-grey-light;
  border-color: $color-text;
}

.tbody {
  .td:first-child {
    font-weight: bold;
  }
}

.td {
  line-height: 16px;
  white-space: nowrap;
  flex-shrink: 0;
}

.tableContainer {
  overflow-x: scroll;
}
</style>