<template>
  <vue-final-modal v-model="showModal" name="export-data-modal">
    <div :id="$style.exportDataModal" :class="$style.container">
      <div :class="$style.content">
        <button
          :class="$style.closeModalButton"
          @click.prevent="showModal = false"
        >
          <BaseIcon icon="times" />
        </button>

        <div :class="$style.header">
          <h2
            :class="$style.fileName"
            @input="onFileNameChange"
            :contenteditable="isEdittingFileName"
            ref="header"
          >
            {{ fileName }}
          </h2>
          <BaseButton
            :class="$style.editIconButton"
            @click="isEdittingFileName = !isEdittingFileName"
          >
            <BaseIcon :icon="isEdittingFileName ? 'times' : 'pencil-alt'" />
          </BaseButton>
        </div>
        <div :class="$style.reportTypeRadiosContainer">
          <div :class="$style.reportTypeRadiosBlock">
            <input
              v-model="selectedExportType"
              id="report-type-full"
              type="radio"
              name="report-type"
              :value="EXPORT_TYPE.FULL"
            />
            <label :class="$style.reportTypeLabel" for="report-type-full">
              Full Report</label
            >
          </div>
          <div :class="$style.reportTypeRadiosBlock">
            <input
              v-model="selectedExportType"
              id="report-type-hfa"
              type="radio"
              name="report-type"
              :value="EXPORT_TYPE.HFA"
            />
            <label :class="$style.reportTypeLabel" for="report-type-hfa">
              ISRC + HFA Only</label
            >
          </div>
        </div>

        <div>
          <h3 :class="$style.previewHeader">Preview</h3>
        </div>
        <div>
          <PreviewTable :headers="previewHeaders" :data="previewData" />
        </div>
        <div :class="$style.actionsContainer">
          <BaseButton variant="primary-solid" @click="save">Save</BaseButton>
        </div>
      </div>
    </div>
  </vue-final-modal>
</template>
<script>
import { nanoid } from "nanoid";
import PreviewTable from "./PreviewTable";
const EXPORT_TYPE = {
  FULL: "Full Report",
  HFA: "ISRC + HFA Only",
};

const truncate = (str, len = 150) => {
  if (str && str.length > len) {
    return str.slice(0, len).padEnd(len + 3, ".");
  }
  return str;
};

export default {
  name: "ExportDataModal",
  components: {
    PreviewTable,
  },
  props: {
    items: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      showModal: false,
      selectedExportType: EXPORT_TYPE.FULL,
      isEdittingFileName: false,
      // fileName: `MTW_Matches-${new Intl.DateTimeFormat("en-US").format(
      //   new Date()
      // )}`,
    };
  },
  watch: {
    async isEdittingFileName(value) {
      if (!value) return;
      await this.$nextTick();
      this.$refs.header.focus();
    },
  },
  computed: {
    previewHeaders() {
      if (!this.previewData?.length) return [];
      return Object.keys(this.previewData[0]).map((header) => ({
        text: header,
        id: nanoid(),
      }));
    },
    dataToExport() {
      switch (this.selectedExportType) {
        case EXPORT_TYPE.FULL: {
          return this.items;
        }
        case EXPORT_TYPE.HFA: {
          const isPresentIsrc = {};
          const data = [];
          for (const { isrc, hfa_code } of this.items) {
            // skip isrc if it was already added
            if (isPresentIsrc[isrc]) {
              continue;
            }

            data.push({ isrc, hfa_code });

            isPresentIsrc[isrc] = true;
          }

          return data;
        }
        default:
          return this.items;
      }
    },
    previewData() {
      const textLimit = 50;
      return this.dataToExport.slice(0, 10).map((item) => {
        const { title, interested_party } = item;
        return {
          ...item,
          ...(EXPORT_TYPE.selectedExportType === EXPORT_TYPE.FULL && {
            title: truncate(title, textLimit),
            interested_party: truncate(interested_party, textLimit),
          }),
        };
      });
    },
  },
  methods: {
    onFileNameChange(e) {
      this.fileName = e.target.innerText.trim();
    },
    save() {
      this.$emit("save", {
        data: this.dataToExport,
        headers: this.previewHeaders.map(({ text }) => text),
        fileName: `${this.fileName}.csv`,
      });
    },
  },
  created() {
    this.fileName = `MTW_Matches-${new Intl.DateTimeFormat("en-US").format(
      new Date()
    )}`;

    this.EXPORT_TYPE = EXPORT_TYPE;
  },
};
</script>

 <style lang="scss" module>
$primary: #bd7eee;
.container {
  border-radius: 10px;
  background: white;
  min-height: 90vh;
  max-width: 90vw;
  width: 100%;
  position: relative;
  top: 5vh;
  left: 5vh;
  display: flex;
  flex-direction: column;
  max-height: 90vh;
  overflow-y: auto;
}
.content {
  position: relative;
  padding: 4rem;
  flex-grow: 1;
}

.header {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.fileName {
  margin: 0;
}
.previewHeader {
  margin-bottom: 1rem;
}

.reportTypeLabel {
  font-weight: bold;
}

.previewHeader,
.reportTypeLabel {
  color: $primary;
}
.closeModalButton {
  background: transparent;
  border: none;
  padding: 0;
  cursor: pointer;
  position: absolute;
  top: 2rem;
  right: 2rem;
  font-size: 1.25rem;
  color: $primary;
}
.reportTypeRadiosContainer {
  display: flex;
  gap: 1rem;
  margin: 1rem 0 3rem;
}

.reportTypeRadiosBlock {
  display: flex;
  gap: 0.5rem;
}

#exportDataModal {
  .editIconButton {
    color: $color-grey-light;
    min-width: auto;
    padding: 0.5rem;
    &:hover {
      color: $color-primary;
    }
  }

  .fileNameInput {
    border: none;
    font-size: 1.5em;
  }
}

.actionsContainer {
  display: flex;
  justify-content: flex-end;
}
</style>